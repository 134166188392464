import React from "react"
import cx from "classnames"
import Slider from "react-slick"

import Layout from "../components/layout"
import { BEHANCE_URL, IMG } from "../const"
import * as styles from "../assets/styles/wristo.module.scss"
import "slick-carousel/slick/slick.css"
import ContactForm from "../components/contact-form"

const Wristo = () => {
  function SampleNextArrow(props) {
    const { className, onClick } = props
    return (
      <button className={className} onClick={onClick} title="Next">
        Next
      </button>
    )
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props
    return (
      <button className={className} onClick={onClick} title="Previous">
        Previous
      </button>
    )
  }

  const mainSliderSettings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    autoplaySpeed: 8000,
    fade: false,
    cssEase: "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <Layout pageTitle="Wristo project - web development, UI/UX design">
      {({
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <main>
            <div className={styles.mainImageWristo}>
              <picture>
                {/* 0..374 */}
                <source
                  media="(max-width: 374px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-0_374.avif,
                    ${IMG.WRISTO}/cover-0_374@2x.avif 2x,
                    ${IMG.WRISTO}/cover-0_374@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(max-width: 374px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-0_374.webp,
                    ${IMG.WRISTO}/cover-0_374@2x.webp 2x,
                    ${IMG.WRISTO}/cover-0_374@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(max-width: 374px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-0_374.jpg
                    ${IMG.WRISTO}/cover-0_374@2x.jpg 2x,
                    ${IMG.WRISTO}/cover-0_374@3x.jpg 3x`}
                />
                {/* 375..479 */}
                <source
                  media="(min-width: 375px) and (max-width: 479px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-375_479.avif,
                    ${IMG.WRISTO}/cover-375_479@2x.avif 2x,
                    ${IMG.WRISTO}/cover-375_479@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(min-width: 375px) and (max-width: 479px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-375_479.webp,
                    ${IMG.WRISTO}/cover-375_479@2x.webp 2x,
                    ${IMG.WRISTO}/cover-375_479@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 375px) and (max-width: 479px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-375_479.jpg,
                    ${IMG.WRISTO}/cover-375_479@2x.jpg 2x,
                    ${IMG.WRISTO}/cover-375_479@3x.jpg 3x`}
                />
                {/* 480..639 */}
                <source
                  media="(min-width: 480px) and (max-width: 639px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-480_639.avif,
                    ${IMG.WRISTO}/cover-480_639@2x.avif 2x,
                    ${IMG.WRISTO}/cover-480_639@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(min-width: 480px) and (max-width: 639px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-480_639.webp,
                    ${IMG.WRISTO}/cover-480_639@2x.webp 2x,
                    ${IMG.WRISTO}/cover-480_639@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 480px) and (max-width: 639px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-480_639.jpg,
                    ${IMG.WRISTO}/cover-480_639@2x.jpg 2x,
                    ${IMG.WRISTO}/cover-480_639@3x.jpg 3x`}
                />
                {/* 640..767 */}
                <source
                  media="(min-width: 640px) and (max-width: 767px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-640_767.avif,
                    ${IMG.WRISTO}/cover-640_767@2x.avif 2x,
                    ${IMG.WRISTO}/cover-640_767@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(min-width: 640px) and (max-width: 767px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-640_767.webp,
                    ${IMG.WRISTO}/cover-640_767@2x.webp 2x,
                    ${IMG.WRISTO}/cover-640_767@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 640px) and (max-width: 767px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-640_767.jpg,
                    ${IMG.WRISTO}/cover-640_767@2x.jpg 2x,
                    ${IMG.WRISTO}/cover-640_767@3x.jpg 3x`}
                />
                {/* 768..1023 */}
                <source
                  media="(min-width: 768px) and (max-width: 1023px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-768_1023.avif,
                    ${IMG.WRISTO}/cover-768_1023@2x.avif 2x,
                    ${IMG.WRISTO}/cover-768_1023@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(min-width: 768px) and (max-width: 1023px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-768_1023.webp,
                    ${IMG.WRISTO}/cover-768_1023@2x.webp 2x,
                    ${IMG.WRISTO}/cover-768_1023@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 768px) and (max-width: 1023px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-768_1023.jpg,
                    ${IMG.WRISTO}/cover-768_1023@2x.jpg 2x,
                    ${IMG.WRISTO}/cover-768_1023@3x.jpg 3x`}
                />
                {/* 1024..1199 */}
                <source
                  media="(min-width: 1024px) and (max-width: 1199px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-1024_1199.avif,
                    ${IMG.WRISTO}/cover-1024_1199@2x.avif 2x,
                    ${IMG.WRISTO}/cover-1024_1199@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(min-width: 1024px) and (max-width: 1199px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-1024_1199.webp,
                    ${IMG.WRISTO}/cover-1024_1199@2x.webp 2x,
                    ${IMG.WRISTO}/cover-1024_1199@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 1024px) and (max-width: 1199px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-1024_1199.jpg,
                    ${IMG.WRISTO}/cover-1024_1199@2x.jpg 2x,
                    ${IMG.WRISTO}/cover-1024_1199@3x.jpg 3x`}
                />
                {/* 1200..1439 */}
                <source
                  media="(min-width: 1200px) and (max-width: 1439px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-1200_1439.avif,
                    ${IMG.WRISTO}/cover-1200_1439@2x.avif 2x,
                    ${IMG.WRISTO}/cover-1200_1439@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(min-width: 1200px) and (max-width: 1439px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-1200_1439.webp,
                    ${IMG.WRISTO}/cover-1200_1439@2x.webp 2x,
                    ${IMG.WRISTO}/cover-1200_1439@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 1200px) and (max-width: 1439px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-1200_1439.jpg,
                    ${IMG.WRISTO}/cover-1200_1439@2x.jpg 2x,
                    ${IMG.WRISTO}/cover-1200_1439@3x.jpg 3x`}
                />
                {/* 1440+ */}
                <source
                  media="(min-width: 1440px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-1440plus.avif,
                    ${IMG.WRISTO}/cover-1440plus@2x.avif 2x,
                    ${IMG.WRISTO}/cover-1440plus@3x.avif 3x`}
                  type="image/avif"
                />
                <source
                  media="(min-width: 1440px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-1440plus.webp,
                    ${IMG.WRISTO}/cover-1440plus@2x.webp 2x,
                    ${IMG.WRISTO}/cover-1440plus@3x.webp 3x`}
                  type="image/webp"
                />
                <source
                  media="(min-width: 1440px)"
                  srcSet={`
                    ${IMG.WRISTO}/cover-1440plus.jpg,
                    ${IMG.WRISTO}/cover-1440plus@2x.jpg 2x,
                    ${IMG.WRISTO}/cover-1440plus@3x.jpg 3x`}
                />
                <img
                  src={`${IMG.WRISTO}/cover-1440plus.jpg`}
                  width="1920"
                  height="1050"
                  alt="Wristo"
                />
              </picture>
              <div
                className={cx(styles.titleWristo, "common__article__section")}
              >
                <h1 className={styles.wristoText}>Wristo</h1>
                <p className={styles.underTitleTextWristo}>
                  Wristo is a smart wristband which caters for the essential
                  needs of everyone in any family.
                </p>
                <a
                  href={BEHANCE_URL.WRISTO}
                  className={styles.visitBtnWristo}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  product demo
                </a>
              </div>
            </div>
            <div className={styles.titleLine}>
              <div className="common__article__section">
                <h2
                  className={cx(styles.titleTextWristo, styles.titleLineAlign)}
                >
                  Services &amp; Technologies
                </h2>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div
                  className={cx(
                    styles.p0,
                    styles.servicesBlock,
                    styles.blockColor1Wristo,
                    "col-xs-12 col-sm-4 col-md-4 col-lg-4"
                  )}
                >
                  <p className={styles.blockTitles}>
                    ui/ux <br className={styles.brline} /> design
                  </p>
                  <p className={styles.blockText}>Adobe Photoshop</p>
                  <p className={styles.blockText}>Adobe Illustrator</p>
                </div>
                <div
                  className={cx(
                    styles.p0,
                    styles.servicesBlock,
                    styles.blockColor2Wristo,
                    "col-xs-12 col-sm-4 col-md-4 col-lg-4"
                  )}
                >
                  <p className={styles.blockTitles}>
                    front-end <br className={styles.brline} /> development
                  </p>
                  <p className={styles.blockText}>JavaScript</p>
                  <p className={styles.blockText}>HTML, CSS</p>
                </div>
                <div
                  className={cx(
                    styles.p0,
                    styles.servicesBlock,
                    styles.blockColor3Wristo,
                    "col-xs-12 col-sm-4 col-md-4 col-lg-4"
                  )}
                >
                  <p className={styles.blockTitles}>
                    back-end <br className={styles.brline} /> development
                  </p>
                  <p className={styles.blockText}>Ruby on Rails</p>
                </div>
              </div>
            </div>
            <div className={styles.termsLine}>
              <div className="common__article__section">
                <p>Terms: Oct 2015–in progress</p>
                <p>Customer’s country: UK</p>
              </div>
            </div>
            <section className="common__article__section">
              <h2>About Wristo</h2>
              <p>
                Wristo is a website created to promote and popularise a new
                device called Wristo. The website is working in a responsive and
                adaptive format and is prepared for future service.
              </p>
              <p>
                Wristo is a smart wristband, an ecosystem which enables 24/7
                connectivity between senior citizens and their caregivers. This
                ecosystem (which we call Wristo™) consists of a wearable
                bracelet worn by an older person and a portal and mobile
                application used by their caregivers to manage the bracelet
                remotely. Caregivers can get readings about the wearer’s
                well-being data and location, set reminders such as medication
                and appointments, store and analyse data. The system has a few
                unique features not available from other products in this
                segment:
              </p>
              <ul className={styles.list}>
                <li>
                  The bracelet has its own SIM card and therefore is fully
                  autonomous. It does not require a paired smartphone.
                </li>
                <li>
                  Its open architecture allows adding additional peripheral
                  devices and sensors, eventually creating an indeed “internet
                  of things” based product. Examples are ECG sensors, remote
                  indoor temperature monitors and many others.
                </li>
                <li>
                  Caregivers can manage all the settings and functions remotely,
                  making it very friendly for senior users.
                </li>
                <li>
                  The system is suitable for private use (e.g. senior citizen
                  and their relative who take care of them) and institutional
                  caregivers such as home care agencies.
                </li>
                <li>
                  The architecture allows storing all the data in the cloud and
                  analysing them to create valuable information for caregivers,
                  medical professionals etc.
                </li>
              </ul>
              <p>
                The website will help you understand how this new device works,
                the advantages and functionality of Wristo. On the page, you can
                find more information about Wristo creators. The main
                functionality allows you to order the Wristo models using a
                simple pre-order form. You can contact creators by sending your
                questions or suggestions using the contact form or find more
                information in the FAQ section.
              </p>
            </section>
            <div className="common__article__section">
              <Slider
                {...mainSliderSettings}
                style={{ width: "100%" }}
                className={styles.wristoCarousel}
              >
                <picture>
                  {/* 0..428 */}
                  <source
                    media="(max-width: 427px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-320.avif,
                      ${IMG.WRISTO}/1-640.avif 2x,
                      ${IMG.WRISTO}/1-1280.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(max-width: 427px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-320.webp,
                      ${IMG.WRISTO}/1-640.webp 2x,
                      ${IMG.WRISTO}/1-1280.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(max-width: 427px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-320.jpg,
                      ${IMG.WRISTO}/1-640.jpg 2x,
                      ${IMG.WRISTO}/1-1280.jpg 3x`}
                  />
                  {/* 480..639 */}
                  <source
                    media="(min-width: 480px) and (max-width: 639px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-480.avif,
                      ${IMG.WRISTO}/1-960.avif 2x,
                      ${IMG.WRISTO}/1-1440.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 428px) and (max-width: 639px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-480.webp,
                      ${IMG.WRISTO}/1-960.webp 2x,
                      ${IMG.WRISTO}/1-1440.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 428px) and (max-width: 639)"
                    srcSet={`
                      ${IMG.WRISTO}/1-480.jpg,
                      ${IMG.WRISTO}/1-960.jpg 2x,
                      ${IMG.WRISTO}/1-1440.jpg 3x`}
                  />
                  {/* 640..959 */}
                  <source
                    media="(min-width: 640) and (max-width: 959px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-640.avif,
                      ${IMG.WRISTO}/1-1280.avif 2x,
                      ${IMG.WRISTO}/1-1920.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 640) and (max-width: 959px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-640.webp,
                      ${IMG.WRISTO}/1-1280.webp 2x,
                      ${IMG.WRISTO}/1-1920.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 640) and (max-width: 959px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-640.jpg,
                      ${IMG.WRISTO}/1-1280.jpg 2x,
                      ${IMG.WRISTO}/1-1920.jpg 3x`}
                  />
                  {/* 960..1279 */}
                  <source
                    media="(min-width: 960px) and (max-width: 1279px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-960.avif,
                      ${IMG.WRISTO}/1-1920.avif 2x,
                      ${IMG.WRISTO}/1-2880.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 960px) and (max-width: 1279px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-960.webp,
                      ${IMG.WRISTO}/1-1920.webp 2x,
                      ${IMG.WRISTO}/1-2880.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 960px) and (max-width: 1279px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-960.jpg,
                      ${IMG.WRISTO}/1-1920.jpg 2x,
                      ${IMG.WRISTO}/1-2880.jpg 3x`}
                  />
                  {/* 1280+ */}
                  <source
                    media="(min-width: 1280px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-1280.avif,
                      ${IMG.WRISTO}/1-2560.avif 2x,
                      ${IMG.WRISTO}/1-3840.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 1280px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-1280.webp,
                      ${IMG.WRISTO}/1-2560.webp 2x,
                      ${IMG.WRISTO}/1-3840.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 1280px)"
                    srcSet={`
                      ${IMG.WRISTO}/1-1280.jpg,
                      ${IMG.WRISTO}/1-2560.jpg 2x,
                      ${IMG.WRISTO}/1-3840.jpg 3x`}
                  />
                  <img
                    src={`${IMG.WRISTO}/1-1280.jpg`}
                    width="1280"
                    height="450"
                    loading="lazy"
                    alt="Wristo"
                    title="Wristo"
                  />
                </picture>
                <picture>
                  {/* 0..428 */}
                  <source
                    media="(max-width: 427px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-320.avif,
                      ${IMG.WRISTO}/2-640.avif 2x,
                      ${IMG.WRISTO}/2-1280.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(max-width: 427px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-320.webp,
                      ${IMG.WRISTO}/2-640.webp 2x,
                      ${IMG.WRISTO}/2-1280.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(max-width: 427px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-320.jpg,
                      ${IMG.WRISTO}/2-640.jpg 2x,
                      ${IMG.WRISTO}/2-1280.jpg 3x`}
                  />
                  {/* 480..639 */}
                  <source
                    media="(min-width: 480px) and (max-width: 639px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-480.avif,
                      ${IMG.WRISTO}/2-960.avif 2x,
                      ${IMG.WRISTO}/2-1440.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 428px) and (max-width: 639px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-480.webp,
                      ${IMG.WRISTO}/2-960.webp 2x,
                      ${IMG.WRISTO}/2-1440.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 428px) and (max-width: 639)"
                    srcSet={`
                      ${IMG.WRISTO}/2-480.jpg,
                      ${IMG.WRISTO}/2-960.jpg 2x,
                      ${IMG.WRISTO}/2-1440.jpg 3x`}
                  />
                  {/* 640..959 */}
                  <source
                    media="(min-width: 640) and (max-width: 959px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-640.avif,
                      ${IMG.WRISTO}/2-1280.avif 2x,
                      ${IMG.WRISTO}/2-1920.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 640) and (max-width: 959px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-640.webp,
                      ${IMG.WRISTO}/2-1280.webp 2x,
                      ${IMG.WRISTO}/2-1920.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 640) and (max-width: 959px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-640.jpg,
                      ${IMG.WRISTO}/2-1280.jpg 2x,
                      ${IMG.WRISTO}/2-1920.jpg 3x`}
                  />
                  {/* 960..1279 */}
                  <source
                    media="(min-width: 960px) and (max-width: 1279px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-960.avif,
                      ${IMG.WRISTO}/2-1920.avif 2x,
                      ${IMG.WRISTO}/2-2880.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 960px) and (max-width: 1279px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-960.webp,
                      ${IMG.WRISTO}/2-1920.webp 2x,
                      ${IMG.WRISTO}/2-2880.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 960px) and (max-width: 1279px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-960.jpg,
                      ${IMG.WRISTO}/2-1920.jpg 2x,
                      ${IMG.WRISTO}/2-2880.jpg 3x`}
                  />
                  {/* 1280+ */}
                  <source
                    media="(min-width: 1280px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-1280.avif,
                      ${IMG.WRISTO}/2-2560.avif 2x,
                      ${IMG.WRISTO}/2-3840.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 1280px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-1280.webp,
                      ${IMG.WRISTO}/2-2560.webp 2x,
                      ${IMG.WRISTO}/2-3840.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 1280px)"
                    srcSet={`
                      ${IMG.WRISTO}/2-1280.jpg,
                      ${IMG.WRISTO}/2-2560.jpg 2x,
                      ${IMG.WRISTO}/2-3840.jpg 3x`}
                  />
                  <img
                    src={`${IMG.WRISTO}/2-1280.jpg`}
                    width="1280"
                    height="450"
                    loading="lazy"
                    alt="Wristo"
                    title="Wristo"
                  />
                </picture>
                <picture>
                  {/* 0..428 */}
                  <source
                    media="(max-width: 427px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-320.avif,
                      ${IMG.WRISTO}/3-640.avif 2x,
                      ${IMG.WRISTO}/3-1280.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(max-width: 427px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-320.webp,
                      ${IMG.WRISTO}/3-640.webp 2x,
                      ${IMG.WRISTO}/3-1280.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(max-width: 427px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-320.jpg,
                      ${IMG.WRISTO}/3-640.jpg 2x,
                      ${IMG.WRISTO}/3-1280.jpg 3x`}
                  />
                  {/* 480..639 */}
                  <source
                    media="(min-width: 480px) and (max-width: 639px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-480.avif,
                      ${IMG.WRISTO}/3-960.avif 2x,
                      ${IMG.WRISTO}/3-1440.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 428px) and (max-width: 639px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-480.webp,
                      ${IMG.WRISTO}/3-960.webp 2x,
                      ${IMG.WRISTO}/3-1440.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 428px) and (max-width: 639)"
                    srcSet={`
                      ${IMG.WRISTO}/3-480.jpg,
                      ${IMG.WRISTO}/3-960.jpg 2x,
                      ${IMG.WRISTO}/3-1440.jpg 3x`}
                  />
                  {/* 640..959 */}
                  <source
                    media="(min-width: 640) and (max-width: 959px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-640.avif,
                      ${IMG.WRISTO}/3-1280.avif 2x,
                      ${IMG.WRISTO}/3-1920.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 640) and (max-width: 959px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-640.webp,
                      ${IMG.WRISTO}/3-1280.webp 2x,
                      ${IMG.WRISTO}/3-1920.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 640) and (max-width: 959px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-640.jpg,
                      ${IMG.WRISTO}/3-1280.jpg 2x,
                      ${IMG.WRISTO}/3-1920.jpg 3x`}
                  />
                  {/* 960..1279 */}
                  <source
                    media="(min-width: 960px) and (max-width: 1279px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-960.avif,
                      ${IMG.WRISTO}/3-1920.avif 2x,
                      ${IMG.WRISTO}/3-2880.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 960px) and (max-width: 1279px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-960.webp,
                      ${IMG.WRISTO}/3-1920.webp 2x,
                      ${IMG.WRISTO}/3-2880.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 960px) and (max-width: 1279px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-960.jpg,
                      ${IMG.WRISTO}/3-1920.jpg 2x,
                      ${IMG.WRISTO}/3-2880.jpg 3x`}
                  />
                  {/* 1280+ */}
                  <source
                    media="(min-width: 1280px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-1280.avif,
                      ${IMG.WRISTO}/3-2560.avif 2x,
                      ${IMG.WRISTO}/3-3840.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    media="(min-width: 1280px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-1280.webp,
                      ${IMG.WRISTO}/3-2560.webp 2x,
                      ${IMG.WRISTO}/3-3840.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    media="(min-width: 1280px)"
                    srcSet={`
                      ${IMG.WRISTO}/3-1280.jpg,
                      ${IMG.WRISTO}/3-2560.jpg 2x,
                      ${IMG.WRISTO}/3-3840.jpg 3x`}
                  />
                  <img
                    src={`${IMG.WRISTO}/3-1280.jpg`}
                    width="1280"
                    height="450"
                    loading="lazy"
                    alt="Wristo"
                    title="Wristo"
                  />
                </picture>
              </Slider>
            </div>
            <section className="common__article__section">
              <h2>Developer’s insight</h2>
              <p>
                “While developing the page, we used the HTML5 approach. HTML5
                Form Validation functionality is secure and simple to use and
                you do not have to add your scripts as everything is ready for
                you. HTML5 video tag supports all modern browsers, including
                Internet Explorer 11 and allows you to apply your own video
                players. We implemented CSS3 features to create a navigation
                panel, which improved page performance. Also, we applied the
                jQuery library to create a dynamic page, and Ajax requests for
                integration of client and server-sides”.
              </p>
              <p>
                <b>Vitaliy Andrushko, Front-end developer</b>
              </p>
              <p>
                “We didn’t use any JavaScript frameworks on the client’s side.
                That’s why we decided to implement a standard RoR approach. The
                page templates were created and processed on server-side using
                RoR operators. I recommend this approach for teams with strong
                Ruby on Rails expertise”.
              </p>
              <p>
                <b>Sophia Nabyvanets, Back-end developer</b>
              </p>
            </section>
            <div className={styles.commentBlockWristo}>
              <section className="common__article__section">
                <picture>
                  <source
                    srcSet={`
                    ${IMG.TESTIMONIALS}/Matvey-Smirnov.avif,
                    ${IMG.TESTIMONIALS}/Matvey-Smirnov@2x.avif 2x,
                    ${IMG.TESTIMONIALS}/Matvey-Smirnov@3x.avif 3x`}
                    type="image/avif"
                  />
                  <source
                    srcSet={`
                    ${IMG.TESTIMONIALS}/Matvey-Smirnov.webp,
                    ${IMG.TESTIMONIALS}/Matvey-Smirnov@2x.webp 2x,
                    ${IMG.TESTIMONIALS}/Matvey-Smirnov@3x.webp 3x`}
                    type="image/webp"
                  />
                  <source
                    srcSet={`
                    ${IMG.TESTIMONIALS}/Matvey-Smirnov.jpg,
                    ${IMG.TESTIMONIALS}/Matvey-Smirnov@2x.jpg 2x,
                    ${IMG.TESTIMONIALS}/Matvey-Smirnov@3x.jpg 3x`}
                  />
                  <img
                    src={`${IMG.TESTIMONIALS}/Matvey-Smirnov.jpg`}
                    alt="Matvey Smirnov"
                    title="Matvey Smirnov"
                    width="80"
                    height="80"
                    loading="lazy"
                    style={{ borderRadius: "100%" }}
                  />
                </picture>
                <p className={styles.commentTitleWristo}>EXCELLENT WORK!</p>
                <p className={styles.commentText}>
                  «This is a young, energetic and yet very experienced team
                  which demonstrated an extremely professional approach».
                </p>
                <p className={styles.commentName}>Matvey Smirnov</p>
                <p className={styles.commentPosition}>
                  Senior R&amp;D Director,
                  <br /> Global Productivity Platform at PepsiCo
                </p>
              </section>
            </div>
            <picture className={styles.wristoMock}>
              {/* 0..428 */}
              <source
                media="(max-width: 427px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-320.avif,
                  ${IMG.WRISTO}/mockups-640.avif 2x,
                  ${IMG.WRISTO}/mockups-1280.avif 3x`}
                type="image/avif"
              />
              <source
                media="(max-width: 427px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-320.webp,
                  ${IMG.WRISTO}/mockups-640.webp 2x,
                  ${IMG.WRISTO}/mockups-1280.webp 3x`}
                type="image/webp"
              />
              <source
                media="(max-width: 427px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-320.jpg,
                  ${IMG.WRISTO}/mockups-640.jpg 2x,
                  ${IMG.WRISTO}/mockups-1280.jpg 3x`}
              />
              {/* 480..639 */}
              <source
                media="(min-width: 480px) and (max-width: 639px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-480.avif,
                  ${IMG.WRISTO}/mockups-960.avif 2x,
                  ${IMG.WRISTO}/mockups-1440.avif 3x`}
                type="image/avif"
              />
              <source
                media="(min-width: 428px) and (max-width: 639px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-480.webp,
                  ${IMG.WRISTO}/mockups-960.webp 2x,
                  ${IMG.WRISTO}/mockups-1440.webp 3x`}
                type="image/webp"
              />
              <source
                media="(min-width: 428px) and (max-width: 639)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-480.jpg,
                  ${IMG.WRISTO}/mockups-960.jpg 2x,
                  ${IMG.WRISTO}/mockups-1440.jpg 3x`}
              />
              {/* 640..959 */}
              <source
                media="(min-width: 640) and (max-width: 959px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-640.avif,
                  ${IMG.WRISTO}/mockups-1280.avif 2x,
                  ${IMG.WRISTO}/mockups-1920.avif 3x`}
                type="image/avif"
              />
              <source
                media="(min-width: 640) and (max-width: 959px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-640.webp,
                  ${IMG.WRISTO}/mockups-1280.webp 2x,
                  ${IMG.WRISTO}/mockups-1920.webp 3x`}
                type="image/webp"
              />
              <source
                media="(min-width: 640) and (max-width: 959px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-640.jpg,
                  ${IMG.WRISTO}/mockups-1280.jpg 2x,
                  ${IMG.WRISTO}/mockups-1920.jpg 3x`}
              />
              {/* 960..1279 */}
              <source
                media="(min-width: 960px) and (max-width: 1279px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-960.avif,
                  ${IMG.WRISTO}/mockups-1920.avif 2x,
                  ${IMG.WRISTO}/mockups-2880.avif 3x`}
                type="image/avif"
              />
              <source
                media="(min-width: 960px) and (max-width: 1279px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-960.webp,
                  ${IMG.WRISTO}/mockups-1920.webp 2x,
                  ${IMG.WRISTO}/mockups-2880.webp 3x`}
                type="image/webp"
              />
              <source
                media="(min-width: 960px) and (max-width: 1279px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-960.jpg,
                  ${IMG.WRISTO}/mockups-1920.jpg 2x,
                  ${IMG.WRISTO}/mockups-2880.jpg 3x`}
              />
              {/* 1280+ */}
              <source
                media="(min-width: 1280px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-1280.avif,
                  ${IMG.WRISTO}/mockups-2560.avif 2x,
                  ${IMG.WRISTO}/mockups-3840.avif 3x`}
                type="image/avif"
              />
              <source
                media="(min-width: 1280px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-1280.webp,
                  ${IMG.WRISTO}/mockups-2560.webp 2x,
                  ${IMG.WRISTO}/mockups-3840.webp 3x`}
                type="image/webp"
              />
              <source
                media="(min-width: 1280px)"
                srcSet={`
                  ${IMG.WRISTO}/mockups-1280.png,
                  ${IMG.WRISTO}/mockups-2560.png 2x,
                  ${IMG.WRISTO}/mockups-3840.png 3x`}
              />
              <img
                src={`${IMG.WRISTO}/mockups-1280.png`}
                width="1280"
                height="450"
                alt="Wristo mockups"
                loading="lazy"
              />
            </picture>
            <section className={styles.contactForm}>
              <ContactForm
                formStatID="CommonFooter"
                handleOpenConfirmation={handleOpenConfirmation}
                isOpenConfirmation={isOpenConfirmation}
                setConfirmationOption={setConfirmationOption}
              />
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default Wristo
